import { template as template_9d488be1d8044db9a5dc1330be014afa } from "@ember/template-compiler";
const FKControlMenuContainer = template_9d488be1d8044db9a5dc1330be014afa(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
