import { template as template_51c8895cbc6a4ee48e0ec36a7cd401ee } from "@ember/template-compiler";
const FKLabel = template_51c8895cbc6a4ee48e0ec36a7cd401ee(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
