import { template as template_c2900bbbf9a24475a28112b3abe3622f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_c2900bbbf9a24475a28112b3abe3622f(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
