import { template as template_585cfddc53a544d7bbdd8d9614df283e } from "@ember/template-compiler";
const SidebarSectionMessage = template_585cfddc53a544d7bbdd8d9614df283e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
